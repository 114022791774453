html {
  min-height: 100%;
}
body {
  background: url('/img/working-hands.jpg') no-repeat;
  background-size: cover;
  background-position: bottom;
  background-blend-mode: overlay;
  background-color: rgba(0,74,82, 1);
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ul {
  list-style-position:
}
form,
.notifications {
  padding: 2rem;
  margin-top: 1rem;
}

form button,form h5 {
  margin: 1rem 0;
}
input[type=text]:not(.browser-default):focus:not([readonly]),
input[type=email]:not(.browser-default):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:not(.browser-default):focus:not([readonly]) {
  border-color: #ec407a;
  box-shadow: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
